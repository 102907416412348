import React from 'react';
import classNames from 'classnames';
import { Anchor, Button, MapMarker } from '@pluralcom/blueprint';

import styles from '../MapMarker.module.scss';

/**
 * Represents an avatar object.
 */
interface AvatarType {
  /** The URL for the original-sized avatar. */
  id: string;
  /** The URL for the small thumbnail of the avatar. */
  smallThumbnail?: string;
  /** The URL for the thumbnail of the avatar. */
  thumbnail?: string;
  /** The URL for the medium-sized avatar. */
  medium?: string;
  /** The URL for the medium-wide-sized avatar. */
  medium_wide?: string;
  /** The URL for the optimized original-sized avatar. */
  original_optimized?: string;
  /** The URL for the original-sized avatar. */
  original?: string;
}

/**
 * Represents a skill object.
 */
interface SkillType {
  /** The unique identifier for the skill. */
  id?: string;
  /** The title of the skill. */
  title?: string;
  /** The price of the skill. */
  price?: number | string;
  /** Whether the skill is hireable or not. */
  is_hireable?: boolean;
}

/**
 * Represents a user object.
 */
interface UserType {
  /** The unique identifier for the user. */
  id: string;
  /** The first name of the user. */
  first_name?: string;
  /** The last name of the user. */
  last_name?: string;
  /** The full name of the user. */
  name?: string;
  /** The avatar of the user. */
  avatar?: AvatarType;
}

interface PureMapMarkerProps {
  /** custom Classname */
  className?: string;
  /** onClick event */
  onClick?: React.MouseEventHandler;
  /**
   * The destination to link to. Can be a string or an object with pathname and search properties.
   */
  to?:
    | string
    | {
        /** pathname */
        pathname: string;
        /** search */
        search?: string;
        /** state */
        state?: any;
      };
  /** href */
  href?: string;
  /** zIndex */
  zIndex?: number;
  /** zIndexHover */
  zIndexHover?: number;
  /** target */
  target?: string;
  /** The skill object */
  skill?: SkillType;
  /** The user object */
  user?: UserType;
  /** The transparent flag */
  transparent?: boolean;
  /** The showSkillTitle flag */
  showSkillTitle?: boolean;
  /** The isApproximate flag */
  isApproximate?: boolean;
  /** The isAbsoluteCenter flag */
  isAbsoluteCenter?: boolean;
}

/**
 *
 * Map Marker component for displaying a map marker on the map with a skill  or user object
 *
 */
const PureMapMarker = ({
  className,
  skill,
  to,
  href,
  onClick,
  zIndex,
  target,
  user,
  transparent,
  showSkillTitle,
  zIndexHover,
  isApproximate,
  isAbsoluteCenter,
  ...rest
}: PureMapMarkerProps) => {
  const Element = to || href ? Anchor : Button;

  return (
    <Element
      to={to}
      style={{ zIndex }}
      target={target}
      href={href}
      onClick={onClick}
      role="button"
    >
      <MapMarker
        className={classNames([
          styles['map-marker'],
          transparent && styles.transparent,
          className,
          // @ts-ignore
          rest?.markerClassName,
        ])}
        zIndex={zIndex}
        skill={skill}
        isAbsoluteCenter={isAbsoluteCenter}
        showSkillTitle={showSkillTitle}
        user={user}
        zIndexHover={zIndexHover}
        isApproximate={isApproximate}
        {...rest}
      />
    </Element>
  );
};

export default PureMapMarker;
