/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

import type { CompleteSetupIntentMutation$data } from './__generated__/CompleteSetupIntentMutation.graphql';

const mutation = graphql`
  mutation CompleteSetupIntentMutation($input: CompleteSetupIntentInput!) {
    completeSetupIntent(input: $input) {
      success
      error {
        message
      }
    }
  }
`;

const completeSetupIntent = ({
  setupIntent,
}: {
  /** Setup intent */
  setupIntent: string,
}): Promise<CompleteSetupIntentMutation$data> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        setupIntent,
      },
    };

    commitMutation({
      mutation,
      variables,

      onCompleted: resolve,
      onError: reject,
    });
  });

export default completeSetupIntent;
