/**
 * @generated SignedSource<<7a51aabf1498345436addf60464e2432>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CompleteSetupIntentInput = {
  clientMutationId?: string | null;
  setupIntent: string;
};
export type CompleteSetupIntentMutation$variables = {
  input: CompleteSetupIntentInput;
};
export type CompleteSetupIntentMutation$data = {
  readonly completeSetupIntent: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly success: boolean | null;
  } | null;
};
export type CompleteSetupIntentMutation = {
  response: CompleteSetupIntentMutation$data;
  variables: CompleteSetupIntentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompleteSetupIntentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompleteSetupIntentPayload",
        "kind": "LinkedField",
        "name": "completeSetupIntent",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompleteSetupIntentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompleteSetupIntentPayload",
        "kind": "LinkedField",
        "name": "completeSetupIntent",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5d8e9bda8ff85139d17a78f032ce5247",
    "id": null,
    "metadata": {},
    "name": "CompleteSetupIntentMutation",
    "operationKind": "mutation",
    "text": "mutation CompleteSetupIntentMutation(\n  $input: CompleteSetupIntentInput!\n) {\n  completeSetupIntent(input: $input) {\n    success\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9e7b254f8d1f39dee53eb6ec3c633c9a";

export default node;
