import React from 'react';

import { Layer, Source, Marker } from 'react-map-gl';
import type { CircleLayer } from 'mapbox-gl';

import { COLOR_BRAND_BLUE_500 } from '@pluralcom/blueprint/dist/styles/js';

import InfoWindow from './components/InfoWindow';
import PureMapMarker from './components/PureMapMarker';

import styles from './MapMarker.module.scss';

/**
 * Represents an avatar object.
 */
interface AvatarType {
  /** The URL for the original-sized avatar. */
  id: string;
  /** The URL for the small thumbnail of the avatar. */
  smallThumbnail?: string;
  /** The URL for the thumbnail of the avatar. */
  thumbnail?: string;
  /** The URL for the medium-sized avatar. */
  medium?: string;
  /** The URL for the medium-wide-sized avatar. */
  medium_wide?: string;
  /** The URL for the optimized original-sized avatar. */
  original_optimized?: string;
  /** The URL for the original-sized avatar. */
  original?: string;
}

/**
 * Represents a user object.
 */
interface UserType {
  /** The unique identifier for the user. */
  id: string;
  /** The first name of the user. */
  first_name?: string;
  /** The last name of the user. */
  last_name?: string;
  /** The full name of the user. */
  name?: string;
  /** The avatar of the user. */
  avatar?: AvatarType;
}

interface MapMarkerProps {
  /** The zIndex of the marker */
  zIndex?: number;
  /** The longitude of the marker */
  lng?: number;
  /** The latitude of the marker */
  lat?: number;
  /** custom marker Style */
  markerStyle?: string;
  /** className */
  className?: string;
  /** show popup on marker */
  withMarkerPopUp?: boolean;
  /** zIndexHover */
  zIndexHover?: number;
  /** user */
  user: UserType;
  /**  The radius of the marker in meter */
  radius?: number;
  /**  The color of the circle */
  circleColor?: string;
  /** The opacity of the circle */
  circleOpacity?: number;
  /** if true, make the marker always point at the center of the map which can be used to set location or share location */
  isApproximateWithBackground?: boolean;
}

/**
 *
 * MapMarker component is a component that represents a marker on the map.
 * It can be used to show the location of a user or a place.
 */
const MapMarker = (props: MapMarkerProps) => {
  const {
    zIndex,
    lat,
    lng,
    withMarkerPopUp = true,
    radius = 500,
    circleColor = COLOR_BRAND_BLUE_500,
    circleOpacity = 0.3,
    isApproximateWithBackground,
    user,
    ...rest
  } = props;
  if (!lat || !lng) {
    return (
      <>
        {withMarkerPopUp ? (
          // @ts-ignore
          <InfoWindow {...props}>
            <PureMapMarker {...props} />
          </InfoWindow>
        ) : (
          <PureMapMarker {...props} />
        )}
      </>
    );
  }

  /**  function to convert zoom level px to meters */
  const metersToPixelsAtMaxZoom = (radiusValue, latitude) =>
    radiusValue / 0.075 / Math.cos((latitude * Math.PI) / 180);

  const geojson = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lng, lat],
        },
      },
    ],
  };

  const layerStyle: CircleLayer = {
    id: user?.id,
    type: 'circle',
    paint: {
      'circle-radius': {
        stops: [
          [0, 0],
          /**
           * first convert radius to diameter : 2*radius
           convert radius to px to show in map as circle works in px
           */
          [20, metersToPixelsAtMaxZoom(radius * 2, lat)],
        ],
        base: 2,
      },
      'circle-color': circleColor,
      'circle-opacity': circleOpacity,
    },
  };

  const renderMarker = () => (
    <Marker
      latitude={lat}
      longitude={lng}
      style={{
        zIndex,
      }}
      className={styles.customMapBoxMarker}
      anchor="center"
      {...rest}
    >
      {withMarkerPopUp ? (
        // @ts-ignore
        <InfoWindow {...props}>
          <PureMapMarker {...props} />
        </InfoWindow>
      ) : (
        <PureMapMarker {...props} />
      )}
    </Marker>
  );
  return isApproximateWithBackground ? (
    // @ts-ignore
    <Source id={user?.id} type="geojson" data={geojson}>
      <Layer {...layerStyle} />
      {renderMarker()}
    </Source>
  ) : (
    renderMarker()
  );
};

export { PureMapMarker };
export default MapMarker;
